import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/Layout/Main";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { useState } from "react";
import UserModel from "../../interfaces/models/user.model";
import { deleteUser } from "../../services/user.service";
import toast from "react-hot-toast";
import { Loading } from "../../components/Loading";
import Modal from "../../components/Modal";

export default function About() {
  const isAuth: boolean = useCheckAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const [perfil, setPerfil] = useState<UserModel>({} as UserModel);

  function handleModalDeleteOpen(perfil: UserModel) {
    setPerfil(perfil);
    setIsModalDeleteOpen(true);
  }

  function handleModalDeleteClose() {
    setIsModalDeleteOpen(false);
    setPerfil({} as UserModel);
  }

  function logout(): void {
    localStorage.clear();
    navigate("/");
  }

  async function handleDeletePerfil(): Promise<void> {
    setLoading(true);

    deleteUser()
      .then(() => {
        toast.success("Perfil deletado com sucesso!");
        logout();
      })
      .catch((err) => {
        toast.error(err.response.data[0] ?? "Erro ao deletar perfil");
      })
      .finally(() => setLoading(false));
  }

  return (
    <MainLayout background="transparent">
      <div className="container-xl text-gilroy-semibold">
        <p className="title-about mb-4">Sobre o SuperHomes</p>
        <p className="description-about">
          Bem-vindo ao SuperHomes! O seu guia definitivo para encontrar
          residências super especiais disponíveis para aluguel por temporada. No
          SuperHomes, você tem ao seu alcance uma seleção única de propriedades
          que prometem transformar qualquer viagem em uma experiência memorável.
        </p>
        <p className="description-about">Explorar a Variedade</p>
        <p className="description-about">
          No SuperHomes, categorizamos cuidadosamente uma ampla gama de
          propriedades para garantir que você encontre exatamente o que procura.
          Navegue pelas seguintes categorias:
        </p>
        <p className="description-about">
          Apartamentos: Espaços urbanos contemporâneos.
        </p>
        <p className="description-about">
          Cabanas: Confortáveis e imersas na natureza.
        </p>
        <p className="description-about">
          Na Árvore: Aventuras elevadas, perfeitas para um refúgio único.
        </p>
        <p className="description-about">
          Charmosas: Aquele toque especial de aconchego e beleza.
        </p>
        <p className="description-about">
          Container: Estadias modernas e sustentáveis.
        </p>
        <p className="description-about">
          Design/Arquitetura: Espaços esteticamente impressionantes.
        </p>
        <p className="description-about">
          Domos: Ambientes esféricos que proporcionam uma experiência de estar
          em um espaço moderno e inovador.
        </p>
        <p className="description-about">
          Eco Sustentável: Propriedades verdes, ideais para os eco-conscientes.
        </p>
        <p className="description-about">
          Fazendas: Vivencie o charme e a tranquilidade do campo.
        </p>
        <p className="description-about">
          Flutuantes: Casas na água para uma escapada serena.
        </p>
        <p className="description-about">
          Glamping: Luxo e natureza combinados para uma aventura glamourosa.
        </p>
        <p className="description-about">
          Histórica: Residências que contam histórias de eras passadas.
        </p>
        <p className="description-about">
          Pé na Areia: Para aqueles que desejam acordar com o som das ondas.
        </p>
        <p className="description-about">
          Retiro Zen: Espaços que promovem paz e renovação.
        </p>
        <p className="description-about">
          Rústicas: Simplicidade encantadora com conforto.
        </p>
        <p className="description-about">
          Sobre Rodas: Propriedades fixas com design inspirado em veículos.
        </p>
        <p className="description-about">
          Temáticas: Casas que oferecem experiências únicas e imersivas.
        </p>
        <p className="description-about">Garantia de Qualidade</p>
        <p className="description-about">
          Cada propriedade listada no SuperHomes passa por um rigoroso processo
          de seleção para garantir que atenda aos nossos elevados padrões de
          qualidade, conforto e singularidade. Nossos critérios incluem não
          apenas a estética e o design, mas também a funcionalidade, a
          localização e a sustentabilidade das instalações.
        </p>
        <p className="description-about">Para Todos os Momentos</p>
        <p className="description-about">
          Seja para celebrar uma ocasião especial, se isolar do mundo em um
          retiro de trabalho remoto, ou desfrutar de uma escapada romântica, o
          SuperHomes oferece a base perfeita. Escolha entre uma variedade de
          propriedades que não só atendem a todas as necessidades, mas também
          enriquecem sua estadia com experiências inesquecíveis.
        </p>
        <p className="description-about">Simplicidade na Reserva</p>
        <p className="description-about">
          Sabemos que o tempo é precioso. Por isso, o SuperHomes simplifica o
          processo de busca e reserva. Com filtros eficientes e informações
          detalhadas, você pode facilmente encontrar e reservar sua estadia
          ideal sem complicações.
        </p>
        <p className="description-about">
          No SuperHomes, sua próxima estadia não é apenas um lugar para dormir,
          mas uma porta para experiências extraordinárias. Explore, descubra e
          viva momentos únicos em propriedades cuidadosamente selecionadas para
          fazer de cada viagem uma celebração da vida.
        </p>
        <p className="description-about">
          Junte-se à comunidade de viajantes que escolhem o SuperHomes para
          tornar cada viagem inesquecível.
        </p>

        {isAuth ? (
          <button
            className="btn btn-danger mt-2 rounded-pill"
            onClick={() => handleModalDeleteOpen(perfil)}
          >
            Excluir conta
          </button>
        ) : null}

        <div className="version mt-4">Versão 1.0.0.0 2020</div>
      </div>

      <Modal close={handleModalDeleteClose} isOpen={isModalDeleteOpen}>
        <div className="row d-flex justify-content-center align-items-center text-center">
          <p className="h5">Deseja excluir PERMANENTEMENTE a sua conta?</p>
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-evenly align-items-center mb-3 mt-3">
            <button
              className="btn btn-danger rounded-pill px-3 py-2 h-100 mb-3 mb-sm-0"
              style={{ width: 175 }}
              onClick={handleModalDeleteClose}
            >
              Cancelar
            </button>

            <button
              className="btn btn-danger rounded-pill px-3 py-2 h-100"
              style={{ width: 175 }}
              onClick={handleDeletePerfil}
            >
              {loading ? (
                <Loading />
              ) : (
                <span className="align-middle">Excluir</span>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
}
