import AppRouter from "./routes/AppRouter";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { getAdmin } from "./services/admin.service";
import { CookieConsentAccept } from "./components/cookies";

export default function App() {
  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <>
      <Toaster />
      <AppRouter />;
      <CookieConsentAccept />
    </>
  );
}
