import PostModel from "../interfaces/models/post.model";
import api from "./client/api-client";
import CategoryModel from "../interfaces/models/category.model";
import PostArgs from "../interfaces/args/post.args";
import PostsRememberArgs from "../interfaces/args/post-remember.args";

export const postPosts = async (args: PostArgs) =>
  await api.post("/posts", args);

export const uploadImage = async (imageData: FormData) =>
  await api.post<{ url: string }>("/uploads/posts", imageData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getPostsFeatured = async (query: string | null) => {
  let url: string = `/posts/getfeatured`;
  url += `?initialDate=2024-02-11-19-47`;
  if (query) {
    url += `&filter=${query}`;
  }

  return await api.get<PostModel[]>(url);
};

export const getPostsNext = async (query: string | null) => {
  let url: string = `/posts/getnext`;
  url += `?initialDate=2024-02-11-19-47`;
  if (query) {
    url += `&filter=${query}`;
  }

  return await api.get<PostModel[]>(url);
};

export const getPostsByCategory = async (
  query: string | null,
  categoryId?: string
) => {
  let url: string = `/posts/getbycategory`;
  url += `?initialDate=2024-02-11-19-47`;
  if (categoryId) {
    url += `&categoryId=${categoryId}`;
  }

  if (query) {
    url += `&filter=${query}`;
  }

  return await api.get<PostModel[]>(url);
};

export const getPostById = async (postId: string) =>
  await api.get<PostModel>(`/posts/${postId}`);

export const getCategories = async () => {
  return await api.get<CategoryModel[]>("/categories");
};

export const getFavorites = async () => {
  let url: string = "/posts/getmine";
  url += "?initialDate=2024-02-11-19-47";

  return await api.get<PostModel[]>(url);
};

export const postSwapFavorite = async (postId: string) => {
  // todo criar args, tipar e preencher body
  let args: PostsRememberArgs = {
    postId: postId,
    remember: true,
  };

  return await api.post(`/posts/remember`, args);
};
