import clsx from "clsx";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../../components/Layout/Admin";
import { Loading } from "../../../components/Loading";
import Modal from "../../../components/Modal";
import PostModel from "../../../interfaces/models/post.model";
import {
  approvePost,
  featurePost,
  getPostsToApprove,
  removePost,
} from "../../../services/admin.service";
import { HiChevronDown } from "react-icons/hi";

export default function AdminPosts() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingApprove, setLoadingApprove] = useState<boolean>(false);
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState<boolean>(false);
  const [isModalFeaturedOpen, setIsModalFeaturedOpen] =
    useState<boolean>(false);
  const [isModalRemoveOpen, setIsModalRemoveOpen] = useState<boolean>(false);
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [postSelected, setPostSelected] = useState<PostModel>({} as PostModel);
  const [filterBy, setFilterBy] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const [filterCriterion, setFilterCriterion] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itensPerPage, setItensPerPage] = useState<number>(100);

  const [filterByApproved, setFilterByApproved] = useState<string>("");
  const [filterByFeatured, setFilterByFeatured] = useState<string>("");

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleFilterAndSort = (
    filterBy: string,
    sortBy: string,
    filterCriterion: string
  ): PostModel[] => {
    let filteredPosts = [...posts];

    // Filtragem
    if (filterBy === "title") {
      filteredPosts.sort((a, b) =>
        (a.title ?? "").toLowerCase() < (b.title ?? "").toLowerCase() ? -1 : 1
      );
    } else if (filterBy === "date") {
      filteredPosts.sort((a, b) =>
        new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1
      );
    } else if (filterBy === "approved") {
      filteredPosts = filteredPosts.filter(
        (post) => post.approved.toString() === filterCriterion
      );
    } /*else if (filterBy === "featured") {
      filteredPosts = filteredPosts.filter(
        (post) => post.featured.toString() === filterCriterion
      );
    }*/

    // Ordenação
    if (sortBy === "asc") {
      filteredPosts.reverse();
    }

    return filteredPosts.filter((post) => {
      if (filterCriterion) {
        if (filterBy === "title" && post.title) {
          return post.title
            .toLowerCase()
            .includes(filterCriterion.toLowerCase());
        } else if (filterBy === "date" && post.createdAt) {
          return post.createdAt
            .toLowerCase()
            .includes(filterCriterion.toLowerCase());
        } else if (filterBy === "approved") {
          return post.approved
            .toString()
            .includes(filterCriterion.toLowerCase());
        } /*else if (filterBy === "featured") {
          return post.featured
            .toString()
            .includes(filterCriterion.toLowerCase());
        }*/
      }

      return true;
    });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const indexOfLastItem = currentPage * itensPerPage;
  const indexOfFirstItem = indexOfLastItem - itensPerPage;
  const currentItems = handleFilterAndSort(filterBy, sortBy, filterCriterion)
    .filter(
      (post) =>
        post.title &&
        post.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  async function handleApprovePost(postId: string): Promise<void> {
    setLoadingApprove(true);

    approvePost(postId)
      .then(() => {
        toast.success("Post aprovado com sucesso");
        setIsModalDetailsOpen(false);
        setPostSelected({} as PostModel);
        loadPosts();
      })
      .catch((error) => {
        toast.error(
          error?.response?.data[0] ??
            "Ocorreu um erro inesperado ao tentar aprovar o post"
        );
      })
      .finally(() => setLoadingApprove(false));
  }

  function handleModalDetailsOpen(post: PostModel) {
    setPostSelected(post);
    setIsModalDetailsOpen(true);
  }

  function handleModalDetailsClose() {
    setIsModalDetailsOpen(false);
    setPostSelected({} as PostModel);
  }

  function handleModalFeaturedOpen(post: PostModel) {
    setPostSelected(post);
    setIsModalFeaturedOpen(true);
  }

  function handleModalFeaturedClose() {
    setIsModalFeaturedOpen(false);
    setPostSelected({} as PostModel);
  }

  async function handleModalFeaturedSubmit() {
    setLoading(true);
    setIsModalFeaturedOpen(false);
    setPostSelected({} as PostModel);

    featurePost(postSelected.id)
      .then(() => {
        toast.success("Destaque alternado com sucesso");
        loadPosts();
      })
      .catch((error) => {
        toast.error(
          error?.response?.data[0] ??
            "Ocorreu um erro inesperado ao tentar alternar o destaque do post"
        );
      });
  }

  function handleModalRemoveOpen(post: PostModel) {
    setPostSelected(post);
    setIsModalRemoveOpen(true);
  }

  function handleModalRemoveClose() {
    setIsModalRemoveOpen(false);
    setPostSelected({} as PostModel);
  }

  async function handleModalRemoveSubmit() {
    setLoading(true);
    setIsModalRemoveOpen(false);
    setPostSelected({} as PostModel);

    removePost(postSelected.id)
      .then(() => {
        toast.success("Post removido com sucesso");
        loadPosts();
      })
      .catch((err) => {
        toast.error(
          err?.response?.data[0] ??
            "Ocorreu um erro inesperado ao tentar remover o post"
        );
      })
      .finally(() => setLoading(false));
  }

  async function loadPosts(): Promise<void> {
    setLoading(true);

    getPostsToApprove()
      .then(({ data }) => {
        setPosts(data);
      })
      .catch((error) => {
        toast.error(error?.response?.data[0] ?? "Ocorreu um erro inesperado");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    loadPosts();
  }, []);

  return (
    <AdminLayout>
      <main className="container-fluid">
        <h1 className="mb-4">Postagens</h1>

        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            {/*
            <button className="btn btn-primary me-4">Aprovadas</button>
            <a href="#" className="text-decoration-none">
              Não Aprovadas
            </a>
          */}
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Pesquisar"
              className="form-control"
            />
          </div>
          <button
            onClick={() => navigate("/admin/posts/new")}
            className="btn btn-primary w-25"
          >
            Adicionar
          </button>
        </div>

        {loading && (
          <div className="d-flex justify-content-center align-items-center">
            <Loading center color="black" />
          </div>
        )}

        {/* Table */}
        {!loading && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="cursor-pointer"
                    onClick={() => setFilterBy("name")}
                  >
                    <span>Title</span>
                    <span className="mx-1">
                      <HiChevronDown />
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer"
                    onClick={() => setFilterBy("date")}
                  >
                    <span>Date</span>
                    <span className="mx-1">
                      <HiChevronDown />
                    </span>
                  </th>
                  <th scope="col">Price</th>
                  <th
                    scope="col"
                    className="cursor-pointer"
                    onClick={() => {
                      setFilterBy("approved");
                      setFilterCriterion(
                        filterByApproved === "true" ? "false" : "true"
                      );
                      setFilterByApproved(
                        filterByApproved === "true" ? "false" : "true"
                      );
                    }}
                  >
                    <span>Approved</span>
                    <span className="mx-1 d-none d-md-block">
                      <HiChevronDown />
                    </span>
                  </th>
                  <th scope="col">Featured</th>
                  <th scope="col">Remember</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((post, index) => (
                  <tr key={index}>
                    <td>{post.title}</td>
                    <td>{post.date}</td>
                    <td>{post.preco}</td>
                    <td>{post.approved ? "Sim" : "Não"}</td>
                    <td>{post.featured ? "Sim" : "Não"}</td>
                    <td>{post.rememberMe ? "Sim" : "Não"}</td>
                    <td>
                      <button
                        onClick={() => handleModalDetailsOpen(post)}
                        className="btn btn-primary mx-1 mb-1"
                      >
                        Details
                      </button>
                      <button
                        className="btn btn-secondary mx-1 mb-1"
                        onClick={() => handleModalFeaturedOpen(post)}
                      >
                        Featured
                      </button>
                      <button
                        onClick={() => handleModalRemoveOpen(post)}
                        className="btn btn-danger mx-1 mb-1"
                      >
                        Remove
                      </button>
                      <button
                        onClick={() => navigate(`/admin/posts/${post.id}/edit`)}
                        className="btn btn-primary mx-1 mb-1"
                      >
                        Editar
                      </button>
                      <button
                        onClick={() =>
                          navigate(`/admin/posts/${post.id}/push-notification`)
                        }
                        className="btn btn-success mx-1 mb-1"
                      >
                        Enviar Push
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <nav aria-label="Pagination">
          <ul className="pagination">
            <li className="page-item">
              <button
                className={`page-link cursor-pointer ${
                  currentPage === 1 ? "disabled-button" : ""
                }`}
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span className="text-black fw-bold">Anterior</span>
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link cursor-pointer"
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  indexOfLastItem >=
                  handleFilterAndSort(filterBy, sortBy, filterCriterion).length
                }
              >
                <span className="text-black fw-bold">Próximo</span>
              </button>
            </li>
          </ul>
        </nav>
      </main>

      {/* Modal Details */}
      <Modal close={handleModalDetailsClose} isOpen={isModalDetailsOpen}>
        <div className="row d-flex justify-content-center align-itens-center text-center">
          <p className="h4 text-black mb-3">{postSelected.title}</p>
          <hr />

          <div className="row mb-4">
            <div className="col-xxl-12 d-flex flex-column align-items-start">
              <p className="text-black text-start mb-3">
                <strong>Title</strong>: {postSelected.title}
              </p>
              <p className="text-black text-start mb-3">
                <strong>Date</strong>: {postSelected.date}
              </p>
              <p className="text-black text-start mb-3">
                <strong>Description</strong>: {postSelected.description}
              </p>
              <p className="text-black text-start mb-3">
                <strong>Category</strong>: {postSelected.category?.title}
              </p>
              <p className="text-black text-start mb-3">
                <strong>Link</strong>: {postSelected.link ?? "Não informado"}
              </p>
              <p className="text-black text-start mb-3">
                <strong>Data inclusão</strong>: {postSelected.createdAt}
              </p>
              <p className="text-black text-start mb-3">
                <strong>Data última alteração</strong>:{" "}
                {postSelected.lastUpdatedAt}
              </p>
              <p className="text-black text-start mb-3">
                <strong>Poster</strong>:{" "}
                <img
                  src={postSelected.poster}
                  alt={postSelected.title ?? "poster"}
                  width="200"
                  height="200"
                />
              </p>
              <p className="text-black text-start mb-3">
                <strong>Approved</strong>:{" "}
                {postSelected.approved ? (
                  "Sim"
                ) : (
                  <button
                    className={clsx(
                      "btn btn-success",
                      loadingApprove && "disabled"
                    )}
                    onClick={() => handleApprovePost(postSelected.id)}
                    disabled={loadingApprove}
                  >
                    Aprovar
                  </button>
                )}
              </p>
              <p className="text-black text-start mb-3">
                <strong>Featured</strong>:{" "}
                {postSelected.featured ? "Sim" : "Não"}
              </p>
              <p className="text-black text-start mb-3">
                <strong>Remember</strong>:{" "}
                {postSelected.rememberMe ? "Sim" : "Não"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xxl-12">
              <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                <button
                  className="w-100 px-3 py-2"
                  onClick={handleModalDetailsClose}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal Featured */}
      <Modal close={handleModalFeaturedClose} isOpen={isModalFeaturedOpen}>
        <div className="row d-flex justify-content-center align-itens-center text-center">
          <p className="h4 text-black mb-3">Confirm toggle featured status?</p>
          <hr />

          <p className="text-black mb-3">{postSelected.title}</p>

          <div className="row mb-4">
            <div className="col-xxl-12">
              <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                <button
                  onClick={handleModalFeaturedClose}
                  className="btn btn-danger w-100 px-3 py-2 mb-3 mb-md-0 me-md-3"
                >
                  No
                </button>

                <button
                  className="btn btn-success w-100 px-3 py-2"
                  onClick={handleModalFeaturedSubmit}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal Remove */}
      <Modal close={handleModalRemoveClose} isOpen={isModalRemoveOpen}>
        <div className="row d-flex justify-content-center align-itens-center text-center">
          <p className="h4 text-black mb-3">Confirm REMOVE?</p>
          <hr />

          <p className="text-black mb-3">{postSelected.title}</p>

          <div className="row mb-4">
            <div className="col-xxl-12">
              <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                <button
                  onClick={handleModalRemoveClose}
                  className="btn btn-secondary w-100 px-3 py-2 mb-3 mb-md-0 me-md-3"
                >
                  No
                </button>

                <button
                  className="btn btn-danger w-100 px-3 py-2"
                  onClick={handleModalRemoveSubmit}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </AdminLayout>
  );
}
