import { FaRegStar, FaWhatsapp } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../components/Layout/Main";
import { useEffect, useState } from "react";
import PostModel from "../../../interfaces/models/post.model";
import {
  getFavorites,
  getPostById,
  postSwapFavorite,
} from "../../../services/posts.service";
import toast from "react-hot-toast";
import { Loading } from "../../../components/Loading";
import IconShare from "../../../components/share";
import { useCheckAuth } from "../../../hooks/useCheckAuth";

export default function PostDetails() {
  const { postId } = useParams();

  const isAuth: boolean = useCheckAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [post, setPost] = useState<PostModel>({} as PostModel);

  const [postsFavorites, setPostsFavorites] = useState<PostModel[]>([]);

  async function fetchFavorites(): Promise<void> {
    setLoading(true);

    getFavorites()
      .then(({ data }) => setPostsFavorites(data))
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao carregar.");
      })
      .finally(() => setLoading(false));
  }

  async function handleFavoritePost(postId: string): Promise<void> {
    setLoading(true);

    if (!isAuth) {
      toast.error("Você deve fazer login para acessar essa função!");
      navigate("/login");
      return;
    }

    postSwapFavorite(postId)
      .then(() => {
        toast.success("Atualizado com sucesso!");
        fetchFavorites();
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao favoritar");
      })
      .finally(() => setLoading(false));
  }

  async function fetchPostsById(): Promise<void> {
    setLoading(true);

    getPostById(postId!)
      .then(({ data }) => {
        setPost(data);
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchPostsById();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  useEffect(() => {
    if (isAuth) {
      fetchFavorites();
    }
  }, []);

  return (
    <MainLayout>
      {loading && <Loading center color="#212121" />}

      {!loading && (
        <div className="container">
          <div className="box p-3 bg-white mb-4">
            <div className="row">
              <div className="col-md-4">
                <img src={post.poster} alt={"Fone"} className="img-details" />
              </div>
              <div className="col-sm-8">
                <img
                  src={`/images/categories/${post.category?.systemKey}_up.svg`}
                  alt={post.category?.title}
                  className="mb-3 mt-3 mt-sm-0"
                />
                <h3 className="text-dark-emphasis mb-3 text-gilroy-semibold">
                  {post.title}
                </h3>
                <h4 className="mb-3">
                  <IoLocationSharp className="icon-color d-inline-block fs-5" />
                  <span className="text-body-secondary fs-6 text-gilroy-semibold">
                    {post.platforms}, {post.estado}
                  </span>
                </h4>
                <span className="text-body-secondary fs-6 text-gilroy-semibold">
                  {post.cidade}
                </span>
                <div className="d-flex flex-column flex-md-row align-items-start justify-content-between">
                  <div className="mt-2">
                    <h1>
                      <span className="fw-bold text-card text-gilroy-bold">
                        R$ {post.pagamento}
                      </span>
                    </h1>
                  </div>
                  <Link
                    to={isAuth ? post.link! : `/login?postId=${postId}`}
                    target={isAuth ? "_blank" : "_self"}
                    rel="noreferrer"
                    className="btn-entrar btn text-white fw-bold order-2 order-lg-1"
                    /*onClick={() => {
                      if (!isAuth) {
                        toast.error(
                          "Você deve realizar o login para acessar o link"
                        );
                      }
                    }}*/
                  >
                    Entrar
                  </Link>
                </div>
                <div className="w-100" style={{ maxWidth: 150 }}>
                  <div className="form-check form-switch d-flex align-items-center">
                    <input
                      className="form-check-input mb-1 cursor-pointer"
                      style={{ width: "3.3em", height: "1.7em" }}
                      type="checkbox"
                      role="switch"
                      id="favoritos"
                      onChange={() => handleFavoritePost(post.id)}
                      checked={postsFavorites
                        .map((post) => post.id)
                        .includes(post.id)}
                      disabled={loading}
                    />
                    <p
                      className="ms-2 text-gilroy-semibold"
                      style={{ marginTop: "12px", color: "#183754" }}
                    >
                      favoritar
                    </p>
                    <FaRegStar
                      className="ms-2 mb-1 fs-6"
                      style={{ color: "#BC985F" }}
                    />
                  </div>
                </div>
                <hr className="mb-3 mt-4" />
                <div className="d-flex align-items-center">
                  <span className="fw-semibold text-gilroy-semibold">
                    Compartilhar:
                  </span>
                  <div className="mx-3 d-flex">
                    <a
                      href={`https://api.whatsapp.com/send?text=Veja a postagem ${
                        post.title
                      } - ${
                        post.preco > 0 ? `R$ ${post.preco}` : post.pagamento
                      }! ${
                        window.location.href
                      } Baixe o SuperHomes para não perder nenhuma postagem`}
                      target="_blank"
                      rel="noreferrer"
                      className="mx-2 icon-whatsapp shadow rounded-circle d-inline-flex align-items-center justify-content-center p-2"
                    >
                      <FaWhatsapp className="text-white" />
                    </a>
                    <IconShare post={post} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="box p-3 bg-white">
            <h4 className="fw-bold text-gilroy-semibold">
              Informações adicionais
            </h4>
            {post.description && post.description.includes("\n") ? (
              <pre
                className="fs-6 font-desc"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {post.description}
              </pre>
            ) : (
              <p
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  display: "inline-block",
                }}
                className="font-desc"
              >
                {post.description}
              </p>
            )}
          </div>
        </div>
      )}
    </MainLayout>
  );
}
