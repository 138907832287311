import MainLayout from "../../components/Layout/Main";

export default function Privacy() {
  return (
    <MainLayout>
      <main role="main" className="container">
        <p>
          <strong>
            TERMOS DE USO, POLÍTICA DE PRIVACIDADE E SEGURANÇA DO APLICATIVO
            SUPERHOMES.ME
          </strong>
        </p>

        <p>
          <strong>1. NORMAS GERAIS</strong>
        </p>

        <p>
          1.1. Este é um contrato firmado entre você, de agora em diante
          denominado como Usuário, e o aplicativo SUPERHOMES.ME
          (SuperHomes), que descreve os termos e condições gerais aplicáveis
          ao uso dos serviços oferecidos.{" "}
        </p>

        <p>
          1.2. É necessário a leitura atenta do contrato antes da utilização dos
          serviços vinculados ao aplicativo SuperHomes, de forma que o Usuário
          tenha conhecimento e, por conseguinte, aceite expressamente os termos
          e condições deste Contrato, incluindo a política de privacidade, em
          conformidade com (i) a Lei Federal n. 12.965, de 23.04.2014 - Marco
          Civil da Internet; (ii) a Lei Federal n. 13.709, de 14.08.2018 - Lei
          de Proteção de Dados Pessoais - LGPDP; e (iii) o Regulamento UE n.
          2016/679, de 27.04.2016 - Regulamento Geral Europeu de Proteção de
          Dados Pessoais - RGDP.{" "}
        </p>

        <p>
          1.3. O SuperHomes é uma ferramenta voltada a ações promocionais
          terceiras e suas divulgações como vestuário, livros, papelaria, dentre
          outros, cujo conteúdo será disponibilizado via link no aplicativo para
          plataformas eletrônicas afins em que serão transmitidos, que não
          guardam qualquer relação e/ou vínculo com o aplicativo.
        </p>

        <p>
          1.4. O Usuário adicionará uma promoção no SuperHomes, que após
          mediação e aprovação, estará disponível no Aplicativo.
        </p>

        <p>
          1.5. O Usuário será responsável por todas as informações cadastradas
          quanto a promoção, mesmo sendo de âmbito público, uma vez publicizado
          pela empresa divulgadora nos diferentes meios de comunicação, sem que
          possa imputar ao SuperHomes qualquer tipo de responsabilidade por
          eventuais imprecisões, erros e/ou conteúdo das informações. Nesse
          sentido, os dados insertos o foram obtidos de forma regular,
          afastando-se qualquer alegação de apropriação indevida dos dados e
          comunicação dos respectivas promoções.{" "}
        </p>

        <p>
          1.6. Este Contrato rege o uso do aplicativo SuperHomes em distintas
          plataformas sejam para dispositivos móveis (Android, IOS, Windows
          Mobile e Desktop), servidores, computadores, notebooks, smartphones,
          tablets, e quaisquer outros dispositivos que se conectem a Internet ou
          serviços web.{" "}
        </p>

        <p>
          1.7. O aplicativo SuperHomes será disponibilizado para pessoas que
          tenham capacidade legal para contratar, prescindindo, todavia, o
          Usuário menor de idade ou declarado incapaz, em quaisquer aspectos, de
          consentimento específico e em destaque dado por pelo menos um dos pais
          ou pelo responsável legal para sua contratação. Caso um Usuário deseja
          se cadastrar por meio de uma Pessoa Jurídica, este assim exercerá seu
          cadastro, respeitando-se os termos e as condições gerais de uso.{" "}
        </p>

        <p>
          <strong>2. REGISTRO </strong>
        </p>

        <p>
          2.1. É necessário e obrigatório completar o formulário de registro em
          todos os seus campos com dados válidos para poder utilizar o
          SuperHomes, tais como: nome completo, endereço, e-mail. O futuro
          Usuário deverá preencher suas informações pessoais e/ou jurídicas de
          maneira exata, precisa e verdadeira, assumindo, desde já, o
          compromisso de atualiza-los quando se faça necessário.
        </p>

        <p>
          2.2. O Usuário se responsabiliza pela precisão e veracidade dos dados
          informados e reconhece que a inconsistência destes poderá implicar a
          impossibilidade de utilizar o Aplicativo. O SuperHomes poderá editar
          ou alterar o teor de qualquer informação cadastrada por seus Usuários
          quando identificadas linguagem ofensiva ou que faça apologia de
          práticas ilegais, enfim, qualquer conteúdo que traga informações em
          que se identifica o titular ou que atentem contra a legislação em
          vigor ou a moral.{" "}
        </p>

        <p>
          2.3. Uma vez finalizado corretamente o cadastro o Usuário receberá um
          e-mail automático em seu e-mail registrado neste site, contendo a
          confirmação da sua inscrição.
        </p>

        <p>
          2.4. O Usuário assume inteira responsabilidade pela guarda, sigilo e
          boa utilização do login e senhas cadastrados, isentando o SuperHomes
          de qualquer responsabilidade.{" "}
        </p>

        <p>
          2.5. O login e senha só poderão ser utilizados pelo Usuário
          cadastrado, sendo expressamente proibido o compartilhamento de login
          e/ou Senha com quaisquer terceiros ou de informações do Aplicativo em
          qualquer site ou ambiente virtual.{" "}
        </p>

        <p>
          2.6. O Usuário poderá, a qualquer momento, pleitear a exclusão de sua
          conta no Aplicativo, ficando ressalvada a guarda pelo SuperHomes das
          informações e/ou dados cuja manutenção seja a eles imposta em razão de
          obrigações legais e/ou regulatórias ou, ainda, cuja a manutenção seja
          necessária para cumprimento de ordem judicial, no âmbito de processos
          judiciais e/ou administrativos e questionamento de terceiros
          decorrentes das atividades desempenhadas pelo Usuário no Aplicativo.
        </p>

        <p>
          2.7. O SuperHomes se reserva o direito de solicitar algum comprovante
          e/ou dados adicionais a efeito de corroborar com o registro de dados
          do Usuário, assim como suspender definitiva ou temporariamente o
          Usuário cuja verificação de dados não foi possível{" "}
        </p>

        <p>
          2.8. O Usuário terá acesso a sua conta mediante preenchimento do
          endereço do correio eletrônico vinculado à sua conta e a senha
          elegida.{" "}
        </p>

        <p>
          2.9. O Usuário fica responsável por todas as operações feitas em sua
          conta, salvo a autorização dos promoções efetuado pelo SuperHomes,
          pois o acesso está restrito a utilização de senha, de seu conhecimento
          exclusivo. Devendo o Usuário informar imediatamente, através de meio
          idôneo, qualquer uso não autorizado de sua conta, assim como o acesso
          desta por meio de terceiros, não autorizados pelo Usuário.{" "}
        </p>

        <p>
          2.10.É reservado o direito de recusa de qualquer solicitação de
          registro ou cancelamento de registro do Usuário, sem que seja obrigado
          a comunicação ou exposições de razões de suas decisões pelo
          SuperHomes. Isso não gera quaisquer tipos de indenização ou
          ressarcimento.{" "}
        </p>

        <p>
          <strong>3. VEDAÇÕES</strong>
        </p>

        <p>
          3.1. A licença de uso do SuperHomes é limitada, não transferível, não
          exclusiva, livre de royalties e revogável para baixar, instalar,
          executar e utilizar este aplicativo em seu dispositivo, não
          correspondendo ao direitos sobre o produto.
        </p>

        <p>
          3.2. A venda da conta do Usuário, transferência, cessão, modificação,
          engenharia reversa ou distribuição bem como a cópia de textos, imagens
          ou quaisquer partes nele contido é expressamente proibida.{" "}
        </p>

        <p>
          3.3. A conta é única, pessoal e intransferível, ficando assim,
          proibido que o mesmo Usuário registre ou possua mais de uma conta.
          Caso o SuperHomes detecte contas distintas que contenham dados
          coincidentes ou relacionados, poderá cancelá-las, suspendê-las ou
          desabilitá-las.{" "}
        </p>

        <p>
          3.4. O Usuário não poderá praticar as seguintes ações com relação ao
          SuperHomes, no todo ou em parte, sem prejuízo de outras que sejam
          consideradas ilegais, contrariem a ordem pública ou atentem contra a
          moral e os bons costumes:{" "}
        </p>

        <p>
          (i) prática de quaisquer atos ilícitos e/ou violação da legislação
          vigente;{" "}
        </p>

        <p>
          (ii) Utilizar o Aplicativo e/ou qualquer conteúdo dele constante, no
          todo ou em parte, sob qualquer meio ou forma, com propósito diverso
          daquele a que este se destina e de forma diversa da prevista neste
          Contrato, inclusive divulgando, a qualquer título, a terceiros que não
          tenham ou não devam ter acesso ao Aplicativo;{" "}
        </p>

        <p>
          (iii) Apagar, deturpar, corromper, alterar, editar, adaptar,
          transmitir ou de qualquer forma modificar, sob qualquer meio ou forma,
          no todo ou em parte, o Aplicativo, e/ou qualquer conteúdo dele
          constante;{" "}
        </p>

        <p>
          (iv) Fazer publicidade ou marketing associando sua imagem ao
          SuperHomes, ou a qualquer empresa afiliada, controlada, coligada ou
          pertencente ao seu grupo econômico, para qualquer divulgação dos dados
          contidos neste Aplicativo, bem como de suas marcas;
        </p>

        <p>
          (v) Carregar, enviar, divulgadar e/ou transmitir qualquer conteúdo que
          lese direito da criança e adolesceste; obsceno; difamatório ou
          calunioso; discriminatório; com estímulos a violência física ou moral;
          ou que faça apologia à prática criminosa sob distintas hipóteses;{" "}
        </p>

        <p>
          (vi) utilizar imagens que ilustrem seus promoções que (i) infrinjam
          direitos autorais; (ii) de cunho sexual; ou (iii) façam divulgação de
          atividades ilegais
        </p>

        <p>
          (vii) Carregar, enviar e/ou transmitir qualquer conteúdo que promova
          ou incite o preconceito (inclusive de origem, raça, sexo, cor,
          orientação sexual e idade) ou qualquer forma de discriminação, bem
          como o ódio ou atividades ilegais;{" "}
        </p>

        <p>
          (viii) Ameaçar, coagir, ou causar constrangimento físico ou moral aos
          demais Usuários;{" "}
        </p>

        <p>
          (ix) Realizar atos que causem ou propiciem a contaminação ou
          prejudiquem quaisquer equipamentos do SuperHomes, de suas empresas
          e/ou de terceiros, inclusive por meio de vírus, trojans, malware,
          worm, bot, backdoor, spyware, rootkit, ou por quaisquer outros
          dispositivos que venham a ser criados;
        </p>

        <p>
          (x) Praticar quaisquer atos em relação ao Aplicativo, direta ou
          indiretamente, no todo ou em parte, que possam causar prejuízo ao
          SuperHomes, a qualquer Usuário e/ou a quaisquer terceiros; e
        </p>

        <p>
          (xi) Usar qualquer nome empresarial, marca, nome de domínio, slogan ou
          expressão de propaganda ou qualquer sinal distintivo ou bem de
          propriedade intelectual de titularidade do SuperHomes, ou de qualquer
          empresa afiliada, controlada, coligada ou pertencente ao seu grupo
          econômico.
        </p>

        <p>
          3.5. Em ocorrendo tais situações, haverá uma investigação por parte do
          SuperHomes e o infrator poderá ser sancionado com a suspensão ou o
          cancelamento de sua solicitação de postagem e inclusive a sua inscrição
          de Conta com Usuário, além de qualquer outra forma de sanção que seja
          pertinente, como ações judiciais penais que configurem o delito, ou
          danos cíveis que possa o infrator causar.
        </p>

        <p>
          3.6. Não é permitida nenhuma ação ou uso de dispositivo, software, ou
          outro meio como o propósito de interferir nas atividades e operações
          do SuperHomes, nas solicitações de divulgações, descrições, contas ou
          base de dados do aplicativo. Qualquer intromissão, atividade ou
          tentativa de violação ou que seja contrária os direitos de propriedade
          intelectual e/ou as proibições estipuladas neste Contrato serão
          passíveis de ações legais pertinentes, e sanções previstas por este
          acordo, assim como fará o autor responsável pela indenização dos danos
          ocasionados.
        </p>

        <p>
          <strong>4. Sanções e Suspensões de Operações </strong>
        </p>

        <p>
          4.1. Sem prejuízo de outras medidas, poderá o SuperHomes advertir,
          suspender em caráter temporário ou desabilitar definitivamente a Conta
          de um Usuário ou um agendamento, além de poder aplicar sanção que
          impacte negativamente na reputação de um Usuário, iniciar ações que
          julgue pertinente e suspender a prestação de seus serviços caso:
        </p>

        <p>
          (i) Inflija alguma lei ou norma estipulada no Termo e nas Condições
          Gerais e demais políticas do aplicativo;{" "}
        </p>

        <p>(ii) Não se cumpra os compromissos como Usuário; </p>

        <p>
          (iii) O SuperHomes entenda que o usuário está cometendo atos dolosos
          ou fraudulentos;{" "}
        </p>

        <p>
          (iv) Não seja possível a verificação da identidade do Usuário ou
          qualquer de suas informações não seja correta; e
        </p>

        <p>
          (v) Se o SuperHomes entender que o agendamento ou outras ações podem
          gerar prejuízo para o Usuário que as publicou, para o próprio
          Aplicativo ou outros Usuários. No caso de um Usuário ser suspenso ou
          desabilitado, todas as suas solicitações que houver publicado serão
          removidas do sistema.{" "}
        </p>

        <p>
          <strong>5. ALTERAÇÕES, MODIFICAÇÕES E RESCISÃO </strong>
        </p>

        <p>
          5.1. O SuperHomes poderá, a qualquer tempo, a seu exclusivo critério
          e sem necessidade de qualquer aviso prévio:{" "}
        </p>

        <p>
          (i) Descontinuar, temporária ou permanentemente, a distribuição ou a
          atualização deste aplicativo;{" "}
        </p>

        <p>
          (ii) Reexaminar, alterar e/ou atualizar no todo ou em parte os termos
          e condições deste Contrato; e
        </p>

        <p>
          (iii) Alterar e/ou atualizar as políticas comerciais presentes no
          Aplicativo.{" "}
        </p>

        <p>
          5.2. Qualquer correção deste Instrumento será obrigatória e válida
          imediatamente após a postagem deste Contrato modificado no diretório
          correspondente no Aplicativo. A continuidade do uso do Aplicativo
          implica em concordância com qualquer alteração nos termos e condições
          e deverá ser integralmente observada pelo Usuário.{" "}
        </p>

        <p>
          5.3. O SuperHomes poderá, a qualquer momento, atualizar as
          disposições constantes neste termo, quer seja em decorrência de
          atualização normativa, quer seja por alteração de sua política
          administrativa, se comprometendo a dar a devida publicidade às
          modificações efetivadas no Aplicativo.{" "}
        </p>

        <p>
          5.4. O SuperHomes poderá divulgar notificações ou mensagens através
          do Aplicativo para informar o Usuário a respeito de mudanças nos
          serviços ou no Termo de Uso, Navegação, Política, Privacidade e
          Compartilhamento, ou outros assuntos relevantes.
        </p>

        <p>
          5.5. O Usuário não poderá responsabilizar a SuperHomes, nem seus
          diretores, executivos, funcionários, afiliados, agentes, contratados
          ou licenciadores por quaisquer modificações, suspensões ou
          descontinuidade do aplicativo.
        </p>

        <p>
          5.6. O SuperHomes disponibilizará, via correio eletronico, canal de
          atendimento a fim de que o Usuário tire dúvidas, faça reclamações,
          envie sugestões ou questione os serviços prestados pelo Aplicativo.{" "}
        </p>

        <p>
          <strong>6. Responsabilidade e alcance do conteúdo </strong>
        </p>

        <p>
          6.1. O SuperHomes posiciona-se como um provedor de aplicação de
          internet, com fulcro na Lei nº 12.965, de 23.04.2014 (Marco Civil da
          Internet) e como controlador, com fulcro na LGPDP. Assim, atua no
          âmbito e limites técnico de seu serviço de agendamento, informar e
          franquear o acesso, por meio de redirecionamento do Usuário a outras
          aplicações de internet. Assim, não veiculará conteúdo gerado por
          terceiros em seu aplicativo, excluindo-se, por conseguinte, de ser
          responsabilizado civilmente pelo mesmo.
        </p>

        <p>
          6.2. O SuperHomes poderá monitorar, editar ou vetar o conteúdo acerca
          das informações dos promoções insertos no Aplicativo pelo Usuário caso
          detecte eventual incompatibilidade com (i) as disposições de sua
          política de segurança, organização e vedações contidas neste
          Instrumento; (ii) a legislação em voga; (iii) a proteção e defesa dos
          direitos de propriedade do Aplicativo; e (iv) a segurança pessoal do
          Usuário.{" "}
        </p>

        <p>
          6.3. O SuperHomes não tem relação contratual, acordo comercial,
          econômico e/ou laboral com a transmissão dos promoções insertos pelo
          Usuário, portanto não intervém nas relações por eles estipuladas.
        </p>

        <p>
          6.4. O SuperHomes não se responsabiliza no que se refere a
          existência, qualidade, quantidade, estado, integridade, legitimidade,
          conteúdo e propriedade intelectual dos promoções oferecidos,
          adquiridos ou outorgados ao Usuário, sendo de responsabilidade direta
          ou indireta de seus idealizadores, bem como pela veracidade dos dados
          insertos pelos próprios acerca dos mesmos.
        </p>

        <p>
          6.5. O acesso pelo Usuário a promoção divulgada é de sua inteira
          responsabilidade e facultativa opção de acesso.{" "}
        </p>

        <p>
          6.6. Uma vez reconhecida a ausência de vínculo entre o SuperHomes e a
          promoção divulgada, o Aplicativo não será responsável pelo efetivo
          cumprimento das obrigações de venda ou no aperfeiçoamento das
          operações. O Usuário conhece e aceita que ao realizar o cadastro de
          tais promoções o faz mediante responsabilidade própria. Em nenhum caso
          o SuperHomes será responsável pelos lucros cessantes, ou por qualquer
          outro dano e/ou prejuízo que possa ter sofrido o Usuário devido à
          realização ou eventual cancelamento dos promoções divulgadas.
        </p>

        <p>
          6.7. É aceito pelo Usuário que o SuperHomes não é parte em nenhuma
          operação, tampouco controle sobre a qualidade, segurança ou legalidade
          das promoções divulgadas.
        </p>

        <p>
          6.8. O SuperHomes permite também a contratação de produtos pelo
          Usuário, sendo assim, este declara ciência e concordância quanto a
          este Contrato não expressar todas as regras referente aquela
          contratação, estando limitado a utilização do Aplicativo, tendo em
          vista que, cada empresa patrocinadora e/ou apoiadora e, respectivo,
          produto possui o seu contrato e isso será tratado diretamente nos
          termos e política de privacidade de cada empresa e respectivo produto.{" "}
        </p>

        <p>
          6.9. Os links deste Aplicativo para outras plataformas, sites e/ou
          aplicativos são fornecidos exclusivamente para sua informação e
          conveniência.{" "}
        </p>

        <p>
          6.10. O SuperHomes se exime de qualquer responsabilidade pelo
          conteúdo da plataforma, sites e/ou aplicativos acessado a partir do
          link deste Aplicativo, sendo a relação jurídica advinda desta
          operação, única e exclusiva entre o Usuário e o site acessado, não se
          constituindo, necessariamente, em uma recomendação, patrocínio,
          aprovação, propaganda ou postagem.
        </p>

        <p>
          6.11. A coincidência, ou eventual incompatibilidade, de empresa
          patrocinadora e/ou apoiadora do SuperHomes com a promoção divulgada
          não implica em qualquer relação contratual, acordo comercial e/ou
          econômico entre o Aplicativo e a transmissão agendada, tampouco, em
          ato de concorrência desleal.{" "}
        </p>

        <p>
          6.12. O SuperHomes não se responsabiliza por (i) garantir a
          veracidade da publicidade de terceiros que apareça no Aplicativo; (ii)
          assegurar que um Usuário completará uma operação de aquisição de
          eventuais produtos anunciados; (iii) a capacidade dos Anunciantes para
          vender, comprar serviços e/ou logística de entrega de seus produtos; e
          (iv) correspondência ou contratos que o Usuário firme com terceiros.
        </p>

        <p>
          6.13. O SuperHomes somente disponibiliza espaço virtual ao Usuário e
          eventual empresa patrocinadora e/ou apoiadora e haverá comunicação
          somente através da Internet ou correspondência eletrônica com seu
          Usuário.
        </p>

        <p>
          <strong>7. USO, COLETA E PROCESSAMENTO DE DADOS </strong>
        </p>

        <p>
          7.1. O SuperHomes, como responsável pelas decisões, finalidades e os
          meios de tratamento dos dados pessoais do Usuário, poderá designar
          operador, pessoa física e/ou jurídica, no intuito de auxilia-lo, o
          qual deverá, oportunamente, ser informado no Aplicativo, permanecendo,
          contudo, como responsável direto pelo tratamento dos dados pessoais
          coletados.
        </p>

        <p>
          7.2. Será designado encarregado de proteção de dados tendo como função
          as atividades de informar, aconselhar e controlar o responsável pelo
          tratamento dos dados, bem como as demais pessoas físicas e jurídicas
          que tratem os dados, a respeito das obrigações do SuperHomes nos
          termos da LGPDP, RGDP, e demais normativos pertinentes de forma a
          cooperar com a autoridade de controle competente.
        </p>

        <p>
          7.3. O SuperHomes pode conter links para outras plataformas, sites
          e/ou aplicativos, o que não implica na propriedade ou
          operacionalização destes pelo Aplicativo. E por não deter esse
          controle, o SuperHomes não terá responsabilidade por seus conteúdos,
          materiais, ações e serviços por eles prestados, além de possíveis
          danos ou perdas ocasionadas pelos mesmos. O enlace a outras
          plataformas, sites e/ou aplicativos não implica em uma sociedade,
          relação, aprovação entre estes e o SuperHomes.{" "}
        </p>

        <p>
          7.4. As informações fornecidas pelo próprio Usuário por meio de seus
          dados cadastrais, uso de cookies, endereço de e-mail do Usuário
          optante pelo recebimento de boletim de informações e/ou noticiais,
          eventuais respostas a pesquisas e questionários, ou de outra
          tecnologia que permita armazenar informações a respeito da navegação
          no SuperHomes, em conjunto ou isoladamente, poderão ser coletadas,
          armazenadas, tratadas, processadas e utilizadas pelo SuperHomes, ou
          terceiros por ela indicados, com as seguintes finalidades:
        </p>

        <p>
          (i) desenvolver, manter e aperfeiçoar os recursos e funcionalidades do
          Aplicativo;{" "}
        </p>

        <p>
          (ii) possibilitar o acesso e o uso dos recursos e funcionalidades do
          Aplicativo pelo Usuário;{" "}
        </p>

        <p>
          (iii) analisar o desempenho do Aplicativo, medir sua audiência,
          verificar os hábitos de navegação do Usuário no Aplicativo, a forma
          pela qual chegaram ao Aplicativo (por exemplo, através de links de
          outros sites, buscadores ou diretamente pelo endereço), avaliar
          estatísticas relacionadas ao número de acessos e uso do Aplicativo,
          seus recursos e funcionalidades;{" "}
        </p>

        <p>
          (iv) análises relacionadas à segurança do Aplicativo, aperfeiçoamento
          e desenvolvimento de ferramentas antifraude;{" "}
        </p>

        <p>
          (v) melhorar as experiências de navegação do Usuário no Aplicativo;{" "}
        </p>

        <p>
          (vi) permitir o fornecimento de serviços mais personalizados e
          adequados às necessidades e interesses do Usuário, tais como páginas
          de perfil, atualizações, postagens e promoções, e-mail marketing,
          alertas, conteúdos e anúncios relevantes;{" "}
        </p>

        <p>
          (vii) permitir a comunicação entre o Usuário e o SuperHomes,
          inclusive mediante o envio e recebimento de e-mails, alertas e outras
          formas de comunicação eletrônica; e
        </p>

        <p>
          (viii) identificar os perfis, hábitos e necessidades para eventuais
          ofertas de serviços, produtos e parcerias estratégicas do SuperHomes.
        </p>

        <p>
          7.5. O SuperHomes preserva a privacidade do Usuário e não compartilha
          seus dados e informações com terceiros, a exceção das expressas
          hipóteses previstas nesta cláusula, salvo mediante consentimento do
          próprio Usuário; por força de lei; ordem judicial, administrativa ou
          arbitral; e ante a utilização de promoções de serviços e/ou produtos
          oferecidos pelos apoiadores e/ou parceiros do Aplicativo.
        </p>

        <p>
          7.6. O Usuário está ciente e autoriza que os seus Registros de
          Navegação no Aplicativo sejam fornecidos pelo SuperHomes a seus
          respectivos parceiros ou contratados para prestar qualquer serviço
          relativo ao Aplicativo, sem indicação individualizada do Usuário que
          permita sua identificação e para as finalidades previstas nesta
          cláusula.
        </p>

        <p>
          7.7. O SuperHomes poderá fornecer um agregado de dados estatísticos
          sobre perfil de consumo e padrões de navegação de seu Uusuário a
          terceiros idôneos.{" "}
        </p>

        <p>
          7.8. O SuperHomes poderá fazer uso de cookies (de autenticação;
          segurança; pesquisa, análise e desempenho; e de propaganda), ou de
          outras ferramentas que permitem armazenar e reconhecer dados da sua
          navegação para envio de informação ou rastreamento por este Aplicativo
          ou pelos proprietários de serviços terceiros utilizados por este
          Aplicativo com a finalidade de prestar e aprimorar os serviços
          solicitados pelo Usuário, além das demais finalidades descritas no
          presente Contrato.
        </p>

        <p>
          7.9. O SuperHomes poderá utilizar link ou cookies de redes sociais,
          de forma a facilitar o acesso do Usuário. Cada rede social possui sua
          própria política de privacidade e de proteção de dados pessoais, sendo
          as pessoas físicas e/ou jurídicas que as mantêm responsáveis pelos
          dados coletados e pelas práticas de privacidade adotadas.{" "}
        </p>

        <p>
          7.10. O SuperHomes poderá coletar, armazenar, tratar, processar e
          utilizar as seguintes informações a respeito da navegação do Usuário:
        </p>

        <p>(i) Localização geográfica; </p>

        <p>
          (ii) Câmera para definir uma fotografia ao perfil do Usuário no
          Aplicativo;{" "}
        </p>

        <p>
          (iii) Conta e Contatos para funcionalidade compartilhar o aplicativo
          com amigos e contas pessoais;
        </p>

        <p>
          (iv) Informações referentes à data e hora de uso do Aplicativo por um
          determinado Usuário, a partir de um determinado Endereço IP; e
        </p>

        <p>
          (v) Informações referentes às quantidades de cliques e tentativas de
          uso do Aplicativo, bem como de páginas acessadas pelo Usuário.
        </p>

        <p>
          7.11. O Usuário poderá desabilitar os cookies por meio das opções de
          configuração do seu respectivo navegador, estando ciente e
          reconhecendo, contudo, que é possível que o Aplicativo não desempenhe
          todas as suas funcionalidades.
        </p>

        <p>
          7.12. O Usuário concorda que o SuperHomes possa coletar e usar dados
          técnicos, pessoais e/ou jurídicos de seu dispositivo tais como
          especificações, configurações, versões de sistema operacional, tipo de
          conexão à internet e afins, fornecidos livremente pelo Usuário, ou, no
          caso dos dados de utilização, coletados automaticamente ao se utilizar
          este Aplicativo.{" "}
        </p>

        <p>
          7.13. A menos que especificado diferentemente, todos os dados
          solicitados por este Aplicativo são obrigatórios e a falta de
          fornecimento destes poderá impossibilitar este Aplicativo de
          desempenhar todas as suas funcionalidades.{" "}
        </p>

        <p>
          7.14. Não serão coletados dados sensíveis do Usuário de forma a
          identificar ou que, porventura, possam detectar origem racial ou
          étnica; convicção religiosa; opinião política; filiação a sindicato ou
          a organização de caráter religioso, filosófico ou político; referente
          à saúde ou à vida sexual; de caráter genético; ou possíveis
          intercorrências destes.{" "}
        </p>

        <p>
          7.15. O Usuário é responsável por quaisquer dados públicos, pessoais
          e/ou jurídicos ou de terceiros que forem obtidos, publicados ou
          compartilhados através deste serviço. É de inteira responsabilidade do
          Usuário a autorização de terceiros acerca da utilização e fornecimento
          destes dados para o SuperHomes.{" "}
        </p>

        <p>
          7.16. O Usuário deve saber, entretanto, que caso os seus dados
          pessoais e/ou jurídicos sejam processados para finalidades de
          marketing direto eles podem objetar tal processamento a qualquer
          momento, ficando, todavia, suscetíveis a política de uso e cessação
          todas as funcionalidades de uso do aplicativo. Tal pleito não
          compromete a licitude do tratamento pretérito de seus dados antes do
          pedido de retirada.{" "}
        </p>

        <p>
          7.17. O processamento dos dados é realizado utilizando computadores e
          /ou ferramentas de Tecnologia da Informação habilitadas, seguindo
          procedimentos organizacionais e meios estritamente relacionados com os
          fins indicados neste Contrato.
        </p>

        <p>
          7.18. Além do SuperHomes, em alguns casos, os dados podem ser
          acessados por certos tipos de pessoas encarregadas, envolvidas com a
          operação deste Aplicativo, tais como administração, vendas, marketing,
          administração legal do sistema, ou pessoas externas como fornecedores
          terceirizados de serviços técnicos, provedores de hospedagem, empresas
          de Tecnologia da Informação, agências de comunicação nomeadas, quando
          necessário, como processadores de dados por parte do SuperHomes.{" "}
        </p>

        <p>
          7.19. O SuperHomes poderá armazenar e processar os dados pessoais
          relacionados ao Usuário sem o seu consentimento nas seguintes
          hipóteses:{" "}
        </p>

        <p>
          (i) para o cumprimento de obrigação legal ou regulatória do
          Aplicativo;{" "}
        </p>

        <p>
          (ii) para execução, pela administração pública, de políticas públicas
          previstas em leis ou regulamentos;{" "}
        </p>

        <p>
          (iii) para a realização de estudos por órgão de pesquisa, garantida,
          sempre que possível, a anonimização dos dados pessoais sensíveis;{" "}
        </p>

        <p>
          (iv) quando, a pedido do Usuário, seja necessário para a execução de
          contrato ou de obrigações preliminares a este relacionado;
        </p>

        <p>
          (v) exercício regular de direitos, inclusive em contrato e em processo
          judicial, administrativo e arbitral;{" "}
        </p>

        <p>
          (vi) proteção da vida ou da incolumidade física do titular ou de
          terceiro; e
        </p>

        <p>
          (vii) garantia da prevenção à fraude e à segurança do titular, nos
          processos de identificação e autenticação de cadastro em sistemas
          eletrônicos.
        </p>

        <p>
          7.20. Em qualquer caso, o SuperHomes colaborará para esclarecer qual
          a base jurídica que se aplica ao processamento, e em especial se o
          fornecimento de dados for um requisito obrigatório por força de lei ou
          contratual, ou uma exigência necessária para celebrar um contrato.{" "}
        </p>

        <p>
          7.21. Os dados são processados ​​nas sedes de operação do SuperHomes
          ou em quaisquer outros lugares onde as partes envolvidas com o
          processamento estiverem localizadas.{" "}
        </p>

        <p>
          7.22. Dependendo da localização do Usuário as transferências de dados
          poderão envolver a transferência dos dados do Usuário para outro país
          que não seja o seu.
        </p>

        <p>
          7.23. Além disso, o SuperHomes poderá conservar os dados pessoais
          e/ou jurídicos e de navegação por um prazo maior ante (i) o
          cumprimento de obrigação legal ou regulatória; (ii) estudo por órgão
          de pesquisa, garantida, sempre que possível, a anonimização dos dados
          pessoais; (iii) transferência a terceiro, desde que respeitados os
          requisitos de tratamento de dados dispostos na legislação; e (iv) uso
          exclusivo do SuperHomes, vedado seu acesso por terceiro, e desde que
          anonimizados os dados.{" "}
        </p>

        <p>
          7.24. Assim que o prazo de conservação expirar os dados pessoais serão
          apagados, de forma a não ser mais possível acessar, alterar, excluir,
          bem como porta-los, cessando, assim, a possibilidade de sua exibição.
        </p>

        <p>
          7.25. Os dados pessoais e/ou jurídico e de navegação do Usuário podem
          ser utilizados para fins jurídicos pelo SuperHomes em juízo ou nas
          etapas conducentes à possível ação jurídica decorrente de uso indevido
          deste Aplicativo ou dos serviços relacionados.{" "}
        </p>

        <p>
          <strong>8. DIREITO DO USUÁRIO </strong>
        </p>

        <p>
          8.1. O Usuário possui o direito de ser informado sobre a base jurídica
          das transferências de dados para outros países ou para quaisquer
          organizações internacionais regidas pelo direito internacional público
          e/ou privado, quando pertinente, e sobre as medidas de segurança
          tomadas pelo SuperHomes para proteger os seus dados.{" "}
        </p>

        <p>
          8.2. O Usuário poderá obter informações específicas sobre os
          interesses legítimos perseguidos pelo SuperHomes dentro das seções
          pertinentes deste documento ou entrando em contato com o SuperHomes.
        </p>

        <p>
          8.3. O Usuário poderá exercer determinados direitos a respeito dos
          seus dados processados pelo SuperHomes; tais como:{" "}
        </p>

        <p>(i) retirar a sua anuência pretérita a qualquer momento; </p>

        <p>
          (ii) objetar o processamento dos seus dados caso este se dê sobre
          outra base jurídica que não a de anuência;{" "}
        </p>

        <p>
          (iii) obter informações junto ao SuperHomes acerca da confirmação e o
          modo como está sendo efetuado o processamento e eventual tratamento de
          seus dados, obter revelações sobre determinados aspectos desta
          operação e conseguir uma cópia dos dados que estiverem sendo
          processados;{" "}
        </p>

        <p>(iv) verificar e pedir retificação de seus dados; </p>

        <p>
          (v) restringir, sob determinadas circunstâncias, o processamento dos
          seus dados;
        </p>

        <p>
          (vi) obter informação das entidades públicas e privadas com as quais o
          SuperHomes realizou uso compartilhado de dados;
        </p>

        <p>
          (vii) ter os seus dados pessoais e/ou jurídicos, sob determinadas
          circunstâncias, eliminados;
        </p>

        <p>
          (viii) não ter a totalidade das decisões concernentes aos seus dados
          automatizados, incluídas as destinadas a definir o seu perfil pessoal,
          profissional, de consumo ou os aspectos de sua personalidade;{" "}
        </p>

        <p>
          (ix) receber os seus dados e ter os mesmos transferidos para outro
          controlador. O Usuário possui o direito de receber os seus Dados em um
          formato estruturado, utilizado comumente e apto a ser lido por
          máquinas e, se for viável tecnicamente, fazer com que os mesmos sejam
          transmitidos para outro controlador sem nenhum empecilho. Esta
          determinação se aplica condicionada a que os Dados sejam processados
          por meios automatizados e que o processamento esteja baseado na
          anuência do Usuário, em um contrato do qual o Usuário seja uma das
          partes ou por obrigações pré-contratuais do mesmo; e
        </p>

        <p>
          (x) registrar uma reclamação perante a sua autoridade de proteção de
          dados competente.{" "}
        </p>

        <p>
          8.4. Quaisquer pedidos para exercer os direitos do Usuário pode ser
          direcionado ao SuperHomes através dos dados para contato fornecidos
          neste documento. Estes pedidos podem ser exercidos sem nenhum custo e
          serão atendidos pelo SuperHomes com a maior brevidade possível.{" "}
        </p>

        <p>
          8.5. Além das informações contidas nesta política de privacidade, este
          Aplicativo poderá fornecer ao Usuário informações adicionais e
          contextuais sobre os serviços específicos ou a coleta e processamento
          de dados pessoais e/ou jurídicos e de navegação mediante solicitação.
        </p>

        <p>
          8.6. Para fins de operação e manutenção, este Aplicativo e quaisquer
          serviços de terceiros poderão coletar arquivos que gravam a interação
          com este Aplicativo (logs do sistema) ou usar outros dados pessoais
          e/ou jurídicos, tais como endereço IP, para esta finalidade.
        </p>

        <p>
          <strong>
            9. ISENÇÃO DE GARANTIAS E LIMITAÇÕES DE RESPONSABILIDADE
          </strong>
        </p>

        <p>
          9.1. O SuperHomes estará em contínuo desenvolvimento e pode conter
          imperfeições sendo seu uso sob o risco do Usuário final.
        </p>

        <p>
          9.2. O SuperHomes não se responsabiliza por qualquer dano, perda ou
          prejuízo causados ao Usuário por falha no sistema, no servidor ou na
          Internet.{" "}
        </p>

        <p>
          9.3. Na extensão máxima permitida pela legislação aplicável o
          SuperHomes e seus fornecedores isentam-se de quaisquer garantias e
          condições expressas ou implícitas incluindo, sem limitação, garantias
          de comercialização, adequação a um propósito específico, titularidade
          e não violação no que diz respeito ao aplicativo e qualquer um de seus
          componentes ou ainda à prestação ou não de serviços de suporte.
        </p>

        <p>
          9.4. O SuperHomes tampouco será responsável por qualquer vírus que
          possa infectar o equipamento do Usuário como consequência do acesso,
          uso ou exame do aplicativo web ou a raiz de qualquer transferência de
          dados, arquivos, imagens, textos, ou áudio contidos no mesmo. O
          Usuário não poderá imputar responsabilidade e exigir pagamento de
          lucros cessantes, em virtude de prejuízos resultantes de dificuldades
          técnicas ou falhas no sistema ou Internet.
        </p>

        <p>
          9.5. O SuperHomes não garante o acesso ao uso contínuo ao aplicativo,
          podendo o sistema está em algum momento indisponível devido a
          dificuldades técnicas ou falhas da Internet, por qualquer
          circunstância alheia ao aplicativo. Em quaisquer casos, buscara-se
          reestabelecer com maior velocidade possível, sem que isso possa
          imputar como sua responsabilidade.
        </p>

        <p>
          9.6. O SuperHomes tomará as medidas e adotará técnicas de segurança
          adequadas, considerando-se os custos de implementação, sua natureza,
          contexto, finalidade e riscos inerentes às atividades do Usuário, de
          forma a impedir o acesso não autorizado, divulgação, alteração ou
          destruição não autorizada dos dados do Usuário. No entanto, o
          SuperHomes se se exime de responsabilidade por culpa exclusiva de
          terceiro, como em caso de ataque de hackers ou crackers, ou culpa
          exclusiva do Usuário, como no caso em que ele mesmo transfere seus
          dados a terceiro.
        </p>

        <p>
          9.7. O SuperHomes se compromete a comunicar o Usuário em prazo
          adequado caso ocorra algum tipo de violação da segurança de seus dados
          pessoais que possa lhe causar um alto risco para seus direitos e
          liberdades pessoais
        </p>

        <p>
          <strong>10. PROPRIEDADE INTELECTUAL </strong>
        </p>

        <p>
          10.1. O conteúdo do SuperHomes, além de seus programas, bases de
          dados, redes, arquivos que permitam ao Usuário entrar em sua Conta,
          site, são de propriedade do aplicativo e estão protegidos pelas leis e
          tratados internacionais de direitos do autor, marcas, patentes,
          modelos e desenhos industriais, além das demais leis vigentes em nosso
          país. O uso indevido e a reprodução parcial ou total de tais conteúdos
          fica proibido, salvo por autorização expressa do SuperHomes.
        </p>

        <p>
          10.2. O Usuário não poderá copiar, reproduzir, transmitir, distribuir,
          publicar, explorar, ou transferir qualquer material do SuperHomes,
          sob qualquer formato, eletrônico ou não, para seu uso pessoal ou
          qualquer outra finalidade comercial ou não, sem a devida autorização
          do Aplicativo.{" "}
        </p>

        <p>
          10.3. Pertencem ao SuperHomes todos e quaisquer direitos intelectuais
          sobre o Aplicativo, incluindo, mas não se limitando a:{" "}
        </p>

        <p>
          (i) Todo e qualquer software, aplicativo ou funcionalidade empregado
          pelo SuperHomes para manter e melhorar o funcionamento do Aplicativo;{" "}
        </p>

        <p>(ii) Identidade visual do Aplicativo; e </p>

        <p>
          (iii) Todo e qualquer conteúdo criado e produzido exclusivamente pelo
          SuperHomes, por si ou por terceiros.
        </p>

        <p>
          10.4. O SuperHomes snão detém qualquer direito, registro ou patente
          sobre os eventos e respectivas transmissões.
        </p>

        <p>
          <strong>11. DISPOSIÇÕES GERAIS. </strong>
        </p>

        <p>
          11.1. A tolerância quanto ao eventual descumprimento de quaisquer das
          disposições deste Contrato de Aplicativo por qualquer Usuário não
          constituirá renúncia ao direito de exigir o cumprimento da obrigação.
        </p>

        <p>
          11.2. As marcas registradas, nomes, logotipos e marcas de serviços
          mostrados são legalmente, reconhecidos ou não, do SuperHomes. Nada
          contido nesse Aplicativo deve ser entendido como uma forma de conceder
          licença ou direito de usar qualquer marca registrada sem a autorização
          prévia escrita do dono. O conteúdo textual apresentado nesse
          Aplicativo é propriedade de seu respectivo autor e não pode ser
          reproduzido total ou parcialmente sem sua expressa permissão escrita.{" "}
        </p>

        <p>
          11.3. O Usuário está ciente e concorda com a coleta, armazenamento,
          tratamento, processamento e uso das Informações enviadas e/ou
          transmitidas pelo Usuário nos termos aqui estabelecidos.{" "}
        </p>

        <p>
          11.4. Este Instrumento é regido em todos os seus pontos de acordo com
          a legislação brasileira.{" "}
        </p>

        <p>
          11.5. Quaisquer disputas ou controvérsias oriundas do presente
          Contrato, sua existência, validade, interpretação, alcance e
          cumprimento, serão processadas na Comarca da Capital do Estado de Rio
          de Janeiro com sendo o único competente para resolver os litígios ou
          demandas que venham a envolver as partes em relação ao uso e ao acesso
          ao SuperHomes.{" "}
        </p>

        <p>
          <strong>
            AO CRIAR UMA CONTA PARA A UTILIZAÇÃO DOS SERVIÇOS E PRODUTOS DO
            SuperHomes, VOCÊ CONCORDA QUE LEU E COMPREENDEU OS TERMOS E
            DISPOSIÇÕES DESTE INSTRUMENTO CONTRATUAL E ESTÁ CIENTE DE SEU
            INTEIRO TEOR, ACEITANDO SUAS CONDIÇÕES.
          </strong>
        </p>

        <br />
      </main>
    </MainLayout>
  );
}
