import { useForm } from "react-hook-form";
import Input from "../../components/Form/Input";
import CleanLayout from "../../components/Layout/Clean";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
import { Loading } from "../../components/Loading";
import PasswordResetArgs from "../../interfaces/args/password-reset.args";
import { resetPassword } from "../../services/auth.service";

interface FormValues {
  newPassword: string;
  confirmPassword: string;
}

export default function ResetPassword() {
  const { token } = useParams();

  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  async function handleResetPassword(data: FormValues): Promise<void> {
    let args: PasswordResetArgs = {
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };

    setLoading(true);

    resetPassword(token!, args)
      .then(() => {
        navigate("/login");
        toast.success("Senha alterada com sucesso!");
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao alterar senha");
      })
      .finally(() => setLoading(false));
  }

  return (
    <CleanLayout title="Informe sua nova senha">
      <form onSubmit={handleSubmit(handleResetPassword)} className="w-100 mb-4">
        <div className="row g-3">
          <div className="col-md-6">
            <Input
              control={control}
              errors={errors}
              name="newPassword"
              validation={{
                required: "Campo nova senha é obrigatório",
              }}
              type="password"
              placeholder="Digite sua nova senha aqui"
            />
          </div>

          <div className="col-md-6">
            <Input
              control={control}
              errors={errors}
              name="confirmPassword"
              validation={{
                required: "Campo de confirmação de nova senha é obrigatório",
              }}
              type="password"
              placeholder="Digite novamente sua senha"
            />
          </div>
        </div>

        <button
          type={loading ? "button" : "submit"}
          className="btn btn-green w-100 mt-3 border-0"
          disabled={loading}
        >
          {loading ? (
            <Loading />
          ) : (
            <span className="align-middle">Confirmar</span>
          )}
        </button>
      </form>
    </CleanLayout>
  );
}
