import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../components/Form/Input";
import {
  getUserProfile,
  postUserProfile,
  uploadUserImage,
} from "../../../services/user.service";
import UserModel from "../../../interfaces/models/user.model";
import toast from "react-hot-toast";
import ProfilePostArgs from "../../../interfaces/args/profile-post.args";
import { Loading } from "../../../components/Loading";
import { useCheckAuth } from "../../../hooks/useCheckAuth";

interface FormValues {
  name: string;
  email: string;
  language: string;
  password: string;
}

export const Form = () => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const isAuth: boolean = useCheckAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<UserModel>();
  const [userImage, setUserImage] = useState<string>();

  async function fetchUserProfile(): Promise<void> {
    setLoading(true);

    getUserProfile()
      .then(({ data }) => {
        setData(data);

        setValue("name", data.displayName || "");
        setValue("email", data.email || "");
      })
      .catch((err) => {
        toast.error(
          err?.response?.data[0] ?? "Erro ao carregar dados do usuário"
        );
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchUserProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      email: "",
      language: "Português (BR)",
      password: "",
    },
  });

  function handleUploadClick() {
    inputFileRef.current?.click();
  }

  async function updateProfilePicture(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    setLoading(true);

    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target?.result as string;
        //setUserImage(base64Image);

        const formData = new FormData();
        formData.append("file", file);

        uploadUserImage(formData)
          .then((res) => {
            setUserImage(res.data.url);
          })
          .catch((err) => {
            toast.error(
              err?.response?.data[0] ??
                "Erro ao enviar imagem. Tente novamente."
            );
          })
          .finally(() => {
            setLoading(false);
          });
      };
      reader.readAsDataURL(file);
    }
  }

  async function handleUpdateProfile(data: FormValues): Promise<void> {
    setLoading(true);

    const updatedData: ProfilePostArgs = {
      displayName: data.name,
      profilePicture: userImage,
      email: data.email,
      newPassword: data.password,
    };

    postUserProfile(updatedData)
      .then(() => {
        toast.success("Dados atualizados com sucesso");
        setUserImage(updatedData.profilePicture);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  if (loading) {
    return (
      <div className="py-5">
        <Loading />
      </div>
    );
  }

  return (
    <form
      className="d-flex flex-column align-items-center justify-content-center profile-form"
      onSubmit={handleSubmit(handleUpdateProfile)}
    >
      {!loading && data && (
        <>
          <div className="avatar">
            <img
              src={
                userImage || data?.profilePicture || "/images/minha-conta.png"
              }
              alt="avatar"
            />
            <label className="btn">
              {/* Input file hidden */}
              <input
                ref={inputFileRef}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                multiple={false}
                onChange={updateProfilePicture}
                onClick={handleUploadClick}
              />

              <img
                className="cursor-pointer"
                src="/images/icons/camera-white.png"
                alt="camera"
              />
            </label>
          </div>

          <div
            className="mt-5 mb-4"
            style={{
              width: "100%",
              height: 1,
              margin: "45px 0 25px 0",
              background: "#9b8f79",
            }}
          ></div>

          <div className="row g-3 px-3 px-md-5">
            <div className="col-md-6">
              <label
                htmlFor="name"
                className="profile-label text-gilroy-semibold"
              >
                Nome
              </label>
              <Input
                control={control}
                errors={errors}
                name="name"
                validation={{}}
                type="text"
                className="profile-input text-gilroy-semibold"
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="email"
                className="profile-label text-gilroy-semibold"
              >
                E-mail
              </label>
              <Input
                control={control}
                errors={errors}
                name="email"
                validation={{}}
                type="text"
                className="profile-input text-gilroy-semibold"
              />
            </div>

            <div className="col-md-6">
              <label
                htmlFor="language"
                className="profile-label text-gilroy-semibold"
              >
                Linguagem
              </label>
              <Input
                control={control}
                errors={errors}
                name="language"
                validation={{}}
                type="text"
                className="profile-input text-gilroy-semibold"
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="password"
                className="profile-label text-gilroy-semibold"
              >
                Senha
              </label>
              <Input
                control={control}
                errors={errors}
                name="password"
                validation={{}}
                type="password"
                className="profile-input text-gilroy-semibold"
              />
            </div>
          </div>

          <button type="submit" className="btn btn-dark-gold w-50 mt-5">
            <span className="text-gilroy-semibold">Salvar configurações</span>
          </button>
        </>
      )}

      {!loading && !data && (
        <div className="d-flex align-items-center justify-content-center">
          <p className="text-white text-gilroy-semibold">
            Erro ao carregar dados do usuário
          </p>
        </div>
      )}
    </form>
  );
};
