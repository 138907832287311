import axios from "axios";
import AuthModel from "../../interfaces/models/auth.model";

const defaultOptions = {
  baseURL: "https://api.superhomes.com.br/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-SUPERAPPS-TENANT": "SUPERHOMES",
  },
};

const api = axios.create(defaultOptions);

api.interceptors.request.use((config) => {
  const auth: AuthModel = JSON.parse(localStorage.getItem("auth") ?? "{}");

  if (auth.accessToken) {
    config.headers.Authorization = `Bearer ${auth.accessToken}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    return Promise.reject(error);
  },
);

export default api;
